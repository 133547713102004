import type { NextPage } from 'next';
import { Layout } from '../components/Layout';

const Home: NextPage = () => {
  return (
    <Layout>
      Cligal Signature App
    </Layout>
  )
}

export default Home
