import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { FC, useEffect, useMemo } from 'react';
import { useStore } from '../../store/useStore';

interface MUIProciderProps {
  children: React.ReactNode;
}

export const MUIProvider: FC<MUIProciderProps> = ({ children }) => {
  const direction = useStore(state => state.direction);

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  const cacheRtl = useMemo(() => createCache({
    key: `mui${direction}`,
    stylisPlugins: [prefixer, ...(direction === 'rtl' ? [rtlPlugin] : [])],
  }), [direction]);

  const theme = useMemo(() => createTheme({
    direction,
    palette: {
      primary: {
        main: '#2d4ded'
      }
    }
  }), [direction]);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          {children}
        </StyledEngineProvider>
      </ThemeProvider>
    </CacheProvider >
  );
}