import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { zoomOptions } from './zoomOptions';

interface SigningStore {
  documentNames: string[];
  setDocuments: (documentNames: string[]) => void;
  ownerName: string;
  setOwnerName: (ownerName: string) => void;
  passcode: string;
  setPasscode: (passcode: string) => void;
  hasPasscode: boolean;
  setHasPasscode: (hasPasscode: boolean) => void;
  preventSendingCopyToClient: boolean;
  setPreventSendingCopyToClient: (preventSendingCopyToClient: boolean) => void;
  logoUrl: string;
  setLogoUrl: (logoUrl: string) => void;
  direction: 'rtl' | 'ltr';
  setDirection: (dir: 'rtl' | 'ltr') => void;
  docIndex: number;
  incDocIndex: () => void;
  doc?: Uint8Array;
  setDoc: (newDoc: Uint8Array) => void;
  pageNum: { value: number; exact: boolean };
  setPageNum: (newPageNum: { value: number; exact: boolean }) => void;
  incPageNum: () => void;
  decPageNum: () => void;
  pageCount: number;
  setPageCount: (newPageCount: number) => void;
  zoomIndex: number;
  setZoomIndex: (index: number) => void;
  incZoomIndex: () => void;
  decZoomIndex: () => void;
  areaIdToSignatureData: { [areaId: string]: string };
  areaIdToInputText: { [areaId: string]: string };
  initializeAreaSignatures: (areaIds: string[]) => void;
  initializeAreaText: (areaIds: string[]) => void;
  setAreaIdToSignatureData: (areaId: string, signatureData: string) => void;
  setAreaIdToTextInput: (areaId: string, text: string) => void;
  setAllSignaturesData: (signatureData: string) => void;
}

export const useStore = create(subscribeWithSelector<SigningStore>(set => ({
  documentNames: [],
  setDocuments: documentNames => set({ documentNames }),
  ownerName: '',
  setOwnerName: ownerName => set({ ownerName }),
  passcode: '',
  setPasscode: passcode => set({ passcode }),
  hasPasscode: false,
  setHasPasscode: hasPasscode => set({ hasPasscode }),
  preventSendingCopyToClient: false,
  setPreventSendingCopyToClient: preventSendingCopyToClient => set({ preventSendingCopyToClient }),
  logoUrl: '',
  setLogoUrl: logoUrl => set({ logoUrl }),
  direction: 'ltr',
  setDirection: direction => set({ direction }),
  docIndex: 0,
  incDocIndex: () => set(state => ({ docIndex: state.docIndex + 1 })),
  doc: undefined,
  setDoc: doc => set({ doc }),
  pageNum: { value: 1, exact: true },
  setPageNum: pageNum => set({ pageNum }),
  incPageNum: () => set(state => ({ pageNum: { value: state.pageNum.value + 1, exact: true } })),
  decPageNum: () => set(state => ({ pageNum: { value: state.pageNum.value - 1, exact: true } })),
  pageCount: 0,
  setPageCount: pageCount => set({ pageCount }),
  zoomIndex: zoomOptions.indexOf(SpecialZoomLevel.PageWidth),
  setZoomIndex: zoomIndex => set({ zoomIndex }),
  incZoomIndex: () => set(state => ({ zoomIndex: consecuteIndex(state.zoomIndex, 1) })),
  decZoomIndex: () => set(state => ({ zoomIndex: consecuteIndex(state.zoomIndex, -1) })),
  areaIdToInputText: {},
  areaIdToSignatureData: {},
  initializeAreaSignatures: areaIds => set({ areaIdToSignatureData: Object.fromEntries(areaIds.map(_ => [_, ''])) }),
  initializeAreaText: areaIds => set({ areaIdToInputText: Object.fromEntries(areaIds.map(_ => [_, ''])) }),
  setAllSignaturesData: signatureData => set(state => ({
    areaIdToSignatureData: Object.fromEntries(Object.entries(state.areaIdToSignatureData)
      .map(([areaId, sigData]) => [areaId, signatureData]))
  })),
  setAreaIdToSignatureData: (areaId, signatureData) => set(state => ({
    areaIdToSignatureData: {
      ...state.areaIdToSignatureData,
      [areaId]: signatureData
    }
  })),
  setAreaIdToTextInput: (areaId, text) => set(state => ({
    areaIdToInputText: {
      ...state.areaIdToInputText,
      [areaId]: text
    }
  }))
})));

const consecuteIndex = (zoomIndex: number, consecutation: number) => {
  const currentZoom = zoomOptions[zoomIndex];
  if (typeof currentZoom !== 'number') {
    return zoomOptions.indexOf(1);
  }

  const onlyNumbers = zoomOptions.filter(Number);

  const nextZoomIndex = onlyNumbers.indexOf(currentZoom) + consecutation;
  if (nextZoomIndex === -1 || nextZoomIndex === onlyNumbers.length) {
    return zoomIndex;
  }

  const nextZoom = onlyNumbers[nextZoomIndex];
  return zoomOptions.indexOf(nextZoom);
}