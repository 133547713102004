import { TopBar } from '../TopBar';
import styles from "./index.module.scss";
import { FC, ReactNode, useEffect, useRef } from 'react';
import { MUIProvider } from './MUIProvider';
import { Emitter } from '../../store/eventEmitter';
import { useStore } from '../../store/useStore';
import classNames from 'classnames';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const appRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      Emitter.emit('keydown', event);
    };
    appRef.current?.addEventListener('keydown', handleKey);

    return () => appRef.current?.removeEventListener('keydown', handleKey);
  }, [appRef.current]);

  const hasPasscode = useStore(state => state.hasPasscode);
  const passcode = useStore(state => state.passcode);
  const isPasscodeView = hasPasscode && !passcode;

  return (
    <MUIProvider>
      <div ref={appRef} className={styles.app}>
        <TopBar />
        <div className={classNames(styles.content, {
          [styles.contentPasscode]: isPasscodeView
        })}>
          {children}
        </div>
      </div>
    </MUIProvider>
  )
}
