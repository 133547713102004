import { AppBar } from '@mui/material';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useStore } from '../../store/useStore';
import styles from './index.module.scss';

export const TopBar = () => {
  const logoUrl = useStore(state => state.logoUrl);
  const { locale } = useRouter();

  const hasPasscode = useStore(state => state.hasPasscode);
  const passcode = useStore(state => state.passcode);
  const isPasscodeView = hasPasscode && !passcode;

  return (
    <AppBar position='static' className={classNames(styles.topBar, {
      [styles.topBarPasscode]: isPasscodeView
    })}>
      <img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/logo/en-US.png";
        }}
        className={classNames(styles.logo, {
          [styles.logoPasscode]: isPasscodeView
        })} src={logoUrl || `/logo/${locale}.png`} />
    </AppBar>
  )
}
