import { PluginOnCanvasLayerRender, ViewerState } from '@react-pdf-viewer/core';
import EventEmitter from 'eventemitter3';
import { DependencyList, useCallback, useEffect } from 'react';

const eventEmitter = new EventEmitter();

interface EventToPayload {
  canvasPageRender: PluginOnCanvasLayerRender;
  viewerStateChanged: ViewerState;
  keydown: KeyboardEvent;
}
export type Event = keyof EventToPayload;

export const Emitter = {
  on: <T extends Event>(event: T, fn: (payload: EventToPayload[T]) => void) => eventEmitter.on(event, fn),
  once: <T extends Event>(event: T, fn: (payload: EventToPayload[T]) => void) => eventEmitter.once(event, fn),
  off: <T extends Event>(event: T, fn: (payload: EventToPayload[T]) => void) => eventEmitter.off(event, fn),
  emit: <T extends Event>(event: T, payload: EventToPayload[T]) => eventEmitter.emit(event, payload)
}

Object.freeze(Emitter);

export const useEmitter = <T extends Event>(event: T, fn: (payload: EventToPayload[T]) => void, deps?: DependencyList) => {
  const memoFn = useCallback(fn, [deps]);
  useEffect(() => {
    Emitter.on(event, memoFn);
    return () => {
      Emitter.off(event, memoFn);
    }
  }, [event, memoFn]);
}