import { SpecialZoomLevel } from "@react-pdf-viewer/core";

export const zoomOptions: (number | SpecialZoomLevel)[] = [
  SpecialZoomLevel.ActualSize,
  SpecialZoomLevel.PageFit,
  SpecialZoomLevel.PageWidth,
  0.25,
  0.50,
  0.75,
  0.90,
  1,
  1.1,
  1.25,
  1.5,
  1.75,
  2,
  2.5,
  3,
  4
];